<template>
  <div class="flex flex-col gap-4 mt-14">
    <h1 class="text-2xl font-bold">Balance History</h1>
    <CChart
        :redraw="true"
        type="line"
        :data="chartData"
      />
  </div>
</template>

<script setup>
import { CChart } from '@coreui/vue-chartjs'
import { ref, defineProps, computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { getBalanceHistory } from '../api/balances'

const labels = ref([])
const balanceData = ref([])

const props = defineProps({
  startDate: String,
  endDate: String,
})

function formatDate(date) {
  const [fullDate, time] = date.split('T');
  const [year, month, day] = fullDate.split('-');
  const [hour, minute] = time.split(':');
  return `${day}/${month} ${hour}:${minute}`;
}

// Move fetchData into queryFn
const { data: queryData } = useQuery({
  queryKey: ['balanceHistory', props.startDate, props.endDate],
  queryFn: async () => {
    const response = await getBalanceHistory(props.startDate, props.endDate)
    const data = response.history

    const sortedData = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    console.log(sortedData)

    labels.value = []
    balanceData.value = []
    for (const entry of sortedData) {
      labels.value.push(formatDate(entry.createdAt))
      balanceData.value.push(entry.balance)
    }

    return data
  },
  refetchInterval: 60000
})

const prettyLabels = computed(() => {
  const groupedLabels = []
  for (let i = 0; i < labels.value.length; i++) {
    groupedLabels.push(labels.value[i])
  }
  return groupedLabels
})

const chartData = computed(() => ({
  labels: prettyLabels.value,
  datasets: [
    {
      label: 'Balance',
      backgroundColor: 'rgba(102, 3, 252, 0.2)',
      borderColor: 'rgba(102, 3, 252, 1)',
      pointBackgroundColor: 'rgba(102, 3, 252, 1)',
      pointBorderColor: '#fff',
      data: balanceData.value
    }
  ],
  options: {
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            return Number.isInteger(value) ? value : '';
          }
        }
      }
    }
  }
}));
</script>
