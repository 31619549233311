import { createRouter, createWebHistory } from 'vue-router';
import Metrics from './views/Metrics.vue';
import Orders from './views/Orders.vue';
import CreateOrder from './views/CreateOrder.vue';
import Statistics from './views/Statistics.vue';
import Invoices from './views/Invoices.vue';

const routes = [
    { path: '/', component: Metrics },
    { path: '/orders', component: Orders },
    { path: '/generateOrder', component: CreateOrder },
    { path: '/statistics', component: Statistics },
    { path: '/invoices', component: Invoices },
    { path: '/:pathMatch(.*)', redirect: '/' }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;