import axios from './setup';

export const getBalance = async () => {
    const response = await axios.get(`/balance`);
    return response.data;
}

export const getBalanceHistory = async (startDate, endDate) => {
    const response = await axios.get(`/balance/history?start_date=${startDate}&end_date=${endDate}`);
    return response.data;
}
