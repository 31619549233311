<template>
  <div class="container py-8 mx-auto">
    <div class="flex justify-center w-full">
      <div class="flex flex-col w-1/2 shadow stats">
        <div class="text-center stat w-xl">
          <div class="text-2xl stat-title">Current Balance</div>
          <div class="stat-value">${{ balance }}</div>
        </div>
      </div>
    </div>
    <BalanceChart startDate="2024-10-25" endDate="2024-12-31" />
    <hr class="my-8 border-2 border-gray-200" />
    <h1 class="mt-8 mb-4 text-2xl font-bold">Invoices</h1>
    <div class="flex mb-4">
      <div class="mr-4">
        <label for="typeFilter" class="block text-sm font-medium text-gray-700">Type</label>
        <select id="typeFilter" v-model="typeFilter" class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="todas">Todas</option>
          <option value="emitida">Emitida</option>
          <option value="recibida">Recibida</option>
        </select>
      </div>
      <div class="mr-4">
        <label for="statusFilter" class="block text-sm font-medium text-gray-700">Status</label>
        <select id="statusFilter" v-model="statusFilter" class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="paid">Paid</option>
        </select>
      </div>
      <div class="mr-4">
        <label for="dateRange" class="block text-sm font-medium text-gray-700">Date Range</label>
        <VueTailwindDatepicker 
          name="dateRange"
          v-model="dateRange" 
          :formatter="formatter"
          input-classes="h-12 rounded-lg bg-slate-900"
          overlay
          as-single
          use-range
        />
      </div>
      <button
        class="mt-6 btn btn-secondary"
        @click="fetchInvoices"
      >
        Apply Filters
      </button>
    </div>
    <div class="overflow-x-auto" v-if="invoices && invoices.length > 0">
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="py-2">ID</th>
            <th class="py-2">Client</th>
            <th class="py-2">Supplier</th>
            <th class="py-2">Channel</th>
            <th class="py-2">Status</th>
            <th class="py-2">Price</th>
            <th class="py-2">Interest</th>
            <th class="py-2">Total Price</th>
            <th class="py-2">Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id">
            <td class="px-4 py-2 border">{{ invoice.id }}</td>
            <td class="px-4 py-2 border">{{ invoice.client }}</td>
            <td class="px-4 py-2 border">{{ invoice.supplier }}</td>
            <td class="px-4 py-2 border">{{ invoice.channel }}</td>
            <td class="px-4 py-2 border">{{ invoice.status }}</td>
            <td class="px-4 py-2 border">{{ invoice.price }}</td>
            <td class="px-4 py-2 border">{{ invoice.interest }}</td>
            <td class="px-4 py-2 border">{{ invoice.totalPrice }}</td>
            <td class="px-4 py-2 border">{{ invoice.createdAt }}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center justify-between mt-4">
        <button
          class="btn btn-primary"
          :disabled="currentPage === 1"
          @click="prevPage"
        >
          Previous
        </button>
        <span>Page {{ currentPage }}</span>
        <button
          class="btn btn-primary"
          :disabled="invoices.length < pageSize"
          @click="nextPage"
        >
          Next
        </button>
      </div>
    </div>
    <div v-else>
      <p>No invoices available.</p>
    </div>
    <hr class="my-8 border-2 border-gray-200" />
    <h1 class="mt-8 mb-4 text-2xl font-bold">Pending Invoices for Client 5</h1>
    <div class="flex justify-center w-full mb-5">
      <div class="flex flex-col w-1/2 shadow stats">
        <div class="text-center stat w-xl">
          <div class="text-2xl stat-title">Current Total to be Paid</div>
          <div class="stat-value">${{ totalToBePaid }}</div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto" v-if="filteredInvoices && filteredInvoices.length > 0">
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="py-2">ID</th>
            <th class="py-2">Client</th>
            <th class="py-2">Supplier</th>
            <th class="py-2">Channel</th>
            <th class="py-2">Status</th>
            <th class="py-2">Price</th>
            <th class="py-2">Interest</th>
            <th class="py-2">Total Price</th>
            <th class="py-2">Created At</th>
            <th class="py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in filteredInvoices" :key="invoice.id">
            <td class="px-4 py-2 border">{{ invoice.id }}</td>
            <td class="px-4 py-2 border">{{ invoice.client }}</td>
            <td class="px-4 py-2 border">{{ invoice.supplier }}</td>
            <td class="px-4 py-2 border">{{ invoice.channel }}</td>
            <td class="px-4 py-2 border">{{ invoice.status }}</td>
            <td class="px-4 py-2 border">{{ invoice.price }}</td>
            <td class="px-4 py-2 border">{{ invoice.interest }}</td>
            <td class="px-4 py-2 border">{{ invoice.totalPrice }}</td>
            <td class="px-4 py-2 border">{{ invoice.createdAt }}</td>
            <td class="px-4 py-2 border">
              <button v-if="invoice.status === 'pending' && invoice.supplier != 5" @click="payInvoice(invoice.id)" class="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700">
                Pay
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p>No pending invoices for client 5 available.</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { getBalance } from '@/api/balances'
import BalanceChart from '@/components/BalanceChart.vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker'

const invoices = ref([])
const filteredInvoices = ref([])
const balance = ref(0)
const currentPage = ref(1)
const pageSize = 10
const typeFilter = ref('todas')
const statusFilter = ref('all')

const TODAY = new Date().toISOString().split('T')[0]
const dateRange = ref([new Date(new Date().setDate(new Date().getDate() - 3)).toISOString().split('T')[0], TODAY])

const formatter = ref({
  date: 'YYYY-MM-DD',
  month: 'MMM',
})

const fetchInvoices = async () => {
  try {
    const type = typeFilter.value !== 'todas' ? typeFilter.value : ''
    const status = statusFilter.value !== 'all' ? statusFilter.value : ''
    let queryString = `page_size=50&page=${currentPage.value}&start_date=${dateRange.value[0]}T00:00:00&end_date=${dateRange.value[1]}T23:59:59`
    queryString += type ? `&type=${type}` : ''
    queryString += status ? `&status=${status}` : ''
    const response = await fetch(`https://granizo5.ing.puc.cl/api/invoices?${queryString}`)
    const responseData = await response.json()
    
    invoices.value = responseData.invoices
  } catch (error) {
    console.error('Error fetching invoices:', error)
  }
}

const fetchFilteredInvoices = async () => {
  try {
    const response = await fetch(`https://granizo5.ing.puc.cl/api/invoices?type=recibida&status=pending&page_size=10000&`)
    const responseData = await response.json()
    
    filteredInvoices.value = responseData.invoices
  } catch (error) {
    console.error('Error fetching filtered invoices:', error)
  }
}

const payInvoice = async (invoiceId) => {
  try {
    await fetch(`https://granizo5.ing.puc.cl/api/invoices/paid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ invoice_id: invoiceId })
    })
    fetchInvoices()
    fetchFilteredInvoices()
  } catch (error) {
    console.error('Error paying invoice:', error)
  }
}

const fetchBalance = async () => {
  const response = await getBalance()
  balance.value = response.balance
}

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
    fetchInvoices()
  }
}

const nextPage = () => {
  currentPage.value++
  fetchInvoices()
}

const totalToBePaid = computed(() => {
  return filteredInvoices.value.reduce((total, invoice) => {
    return total + invoice.totalPrice
  }, 0)
})

onMounted(() => {
  fetchBalance()
  fetchInvoices()
  fetchFilteredInvoices()
})

setInterval(fetchBalance, 30000)
setInterval(fetchInvoices, 30000)
setInterval(fetchFilteredInvoices, 30000)
</script>
